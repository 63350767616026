import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>
      <h2>SOFTWARE DEVELOPER</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>At least 5 to 7 years of professional experience in building reliable web or mobile applications</li>
          <li>A Bachelor's Degree in Computer Science, Informations Systems, or a related field</li>
          <li>Solid understanding of OOP principles, design patterns, data-driven and test-driven development, and software development best practices</li>
          <li>Strong programming skills in PHP, Javascript or Ruby</li>
          <li>Excellent communication skills. both written and verbal</li>
          <li>Strong analytical and problem-solving skills</li>
          <li>Meticulous attention to details</li>
          <li>Understands Agile Software Development</li>
          <li>Plus: Ruby on Rails experience</li>
          <li>Knowledge in Modern Front-end Framework (React.js, Angular, Vue.js) is a Plus</li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
